import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import auth  from "./authReducer";
import loading from "./loadingReducer";
import post from "./postReducer";
import target from "./targetReducer";
import group from "./groupReducer";

export const appReducer = combineReducers({
  auth,
  loading,
  post,
  target,
  group
});

const rootReducer = (state, action) => {
  if (action.type === "USER_CLEAR") {
    storage.removeItem("persist:root");
    localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;