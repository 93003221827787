import React from 'react';
// import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import TopNav from "../components/TopNav";


const PrivateLayout = ({children,pathname,hide}) => {
    const profileData = useSelector(state => state.auth.userData);
    return ( 
        <div className="w-full box-border h-screen">
            <TopNav/>
            <div className="w-full bg-gray3 pt-7 layout flex justify-evenly  overflow-hidden">
                {children}
            </div>
        </div>
     );
}

// PrivateLayout.propTypes={
//     children: PropTypes.elementType.isRequired,
//     pathname: PropTypes.string
// };
 
export default PrivateLayout;