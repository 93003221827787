const BASE_URL = {
    production: "https://92r2ggv7u2.execute-api.af-south-1.amazonaws.com/dev",
    staging: "https://92r2ggv7u2.execute-api.af-south-1.amazonaws.com/dev",
    //staging: "http://ec2-13-245-209-129.af-south-1.compute.amazonaws.com:3003",

  };
  
  export const API_KEY="3yBrArNb838bdyIPpLith6dpr0NHCcc66J4AR313";
  export const MIXPANEL_KEY = {
    staging: process.env.REACT_APP_MIXPANEL_KEY_STAGING,
    production: process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION
  };
  
  export default BASE_URL;
  // ec2-13-246-6-107.af-south-1.compute.amazonaws.com:3006