import { ADD_COMMENT, ADD_POST, FETCH_POSTS, FETCH_SINGLE_POSTS, LIKE_POST, UNLIKE_POST , FETCH_POST_COMMENTS, UNSHARE_POST, SHARE_POST} from "../types/postTypes";
  
const INITIAL_STATE = {
  posts: [],
  singlePost: {},
};

const postReducer = (state = INITIAL_STATE, action) => {
    const unlikePost = (state, action) => {
        const arr = state.posts.slice(0);
        const filteredLikes = action?.payload?.likes.filter(item => item.liked_by !== action.id);
        const index = state.posts.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, likes: filteredLikes};
        return arr;
    };
    const likePost = (state, action) => {
        const arr = state.posts.slice(0);
        const index = state.posts.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, likes: [...action.payload.likes,{liked_by: action.id}] };
        return arr;
    };
    const sharePost = (state, action) => {
        const arr = state.posts.slice(0);
        const index = state.posts.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, shares: [...action.payload.shares,{created_by: action.id}] };
        return arr;
    };
    const unsharePost = (state, action) => {
        const arr = state.posts.slice(0);
        const filteredShares = action?.payload?.shares.filter(item => item.created_by !== action.id);
        const index = state.posts.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, shares: filteredShares};
        return arr;
    };

  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        posts: action.payload
      };
    case FETCH_SINGLE_POSTS:
      return {
        ...state,
        singlePost: action.payload
      };
    case FETCH_POST_COMMENTS:
        return {
          ...state,
          singlePost: {...state.singlePost, comments: action.payload}
        };
    case LIKE_POST:
        return {
            ...state,
            posts: likePost(state,action)
        };
    case UNLIKE_POST:
        return {
            ...state,
            posts: unlikePost(state,action)
        };
    case SHARE_POST:
        return {
            ...state,
            posts: sharePost(state,action)
        };
    case UNSHARE_POST:
        return {
            ...state,
            posts: unsharePost(state,action)
        };
    case ADD_POST:
        return {
            ...state,
            posts: [action.payload, ...state.posts]
        }
    case ADD_COMMENT:
        return{
            ...state,
            singlePost: {
                ...state.singlePost,
                comments: [...state.singlePost.comments, action.payload]
            }
        }
    default:
      return state;
  }
};
export default postReducer;