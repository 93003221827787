import { ADD_TASK, FETCH_TARGETS, FETCH_SINGLE_TARGET, ADD_TARGET, MARK_TASK, UNMARK_TASK } from "../types/targetType";
  
const INITIAL_STATE = {
  targets: [],
  singleTarget: {},
};

const targetReducer = (state = INITIAL_STATE, action) => {
    const marktask = (state,action) => {
        const arr = state.singleTarget.tasks.slice(0);
        const index = state.singleTarget.tasks.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, status: true};
        return arr;
    }
    const unmarkTask = (state,action) => {
        const arr = state.singleTarget.tasks.slice(0);
        const index = state.singleTarget.tasks.findIndex(entry => entry.id === action.payload.id);
        arr[index] = {...action.payload, status: false};
        return arr;
    }

  switch (action.type) {
    case FETCH_TARGETS:
      return {
        ...state,
        targets: action.payload
      };
    case FETCH_SINGLE_TARGET:
      return {
        ...state,
        singleTarget: action.payload
      };
    case ADD_TARGET:
        return {
            ...state,
            targets: [...state.targets, action.payload]
        }
    case ADD_TASK:
        return {
            ...state,
            singleTarget: {...state.singleTarget, tasks: [...state.singleTarget.tasks, action.payload]}
        }
    case MARK_TASK:
        return{
            ...state,
            singleTarget: {...state.singleTarget, tasks: marktask(state,action) }
        }
    case UNMARK_TASK:
        return{
            ...state,
            singleTarget: {...state.singleTarget, tasks: unmarkTask(state,action) }
        }
    default:
      return state;
  }
};
export default targetReducer;