import React from "react";
import { useLocation,Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ErrorBoundary from "../components/ErrorBoundary";

const PublicRoute = ({ children,isAuthenticated, }) => {
  let location = useLocation();

  if (isAuthenticated) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/community" state={{ from: location }} replace />;
  }

  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.object
};

const mapStateToProps = ({ loading, auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isLoading: loading.isLoading
});

export default connect(mapStateToProps, {})(PublicRoute);
