import React,{useState} from 'react';
import Icon from '../Icons';
import InputField from '../InputField';


const InputSearch = ({handleSubmit,placeholder,className}) => {
    const [query, setquery] = useState("")
    return ( 
        <form className={className || ""} onSubmit={()=>handleSubmit(query)}>
            <div className='w-full br6 border border-lighterDark flex h-17 py-3 px-2.5'>
                <Icon
                    id="search-icon"
                    width="24px"
                    height="24px"
                />
                <input style={{border:"none"}} className='w-full cursor-pointer border-0 outline-none pl-3 text-is text-gray py-1 bg-transparent' onChange={(e) => setquery(e.target.value)} placeholder={placeholder}/>
            </div>
        </form>
    );
}
 
export default InputSearch;