import { joinGroup } from "../actions/groupAction";
import { FETCH_GROUPS,FETCH_USER_GROUPS, RESET_SEARCH, SEARCH_GROUPS,JOIN_GROUP, FETCH_VYBE_LIST, ADD_GROUP } from "../types/groupTypes";
  
const INITIAL_STATE = {
  userGroups: [],
  data: [],
  query: [],
  vybeList: []
};

const groupReducer = (state = INITIAL_STATE, action) => {
  const joinGroup = (state,action) => {
      const arr = state.data.slice(0);
      const index = state.data.findIndex(entry => entry.id === action.payload._id);
      arr[index] = action.payload;
      return arr;
  }

  switch (action.type) {
    case FETCH_GROUPS:
      return {
        ...state,
        data: action.payload
      };
    case FETCH_USER_GROUPS:
      return {
        ...state,
        userGroups: action.payload
      };
    case SEARCH_GROUPS:
        return {
            ...state,
            query: action.payload
        }
    case RESET_SEARCH:
        return {
            ...state,
            query: []
        }
    case JOIN_GROUP:
        return {
            ...state,
          data: joinGroup(state,action),
          userGroups: [...state.userGroups, action.payload ]
        }
    case FETCH_VYBE_LIST:
        return{
          ...state,
          vybeList: action.payload
        }
    case ADD_GROUP:
        return{
            ...state,
            data: [...state.data, action.payload ]
        }
    default:
      return state;
  }
};
export default groupReducer;