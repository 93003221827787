/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Redirect, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import history from "../helpers/history";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PrivateLayout from "../layout/PrivateLayout";
import PageLoader from "../components/Loader";

const AllRoutes = (props) => {
  const Login = lazy(() => import("../pages/auth/login"));
  const SignUp = lazy(() => import("../pages/auth/SignUp"));
  const Community = lazy(() => import("../pages/Community"));
  const Events = lazy(() => import("../pages/Events"));
  const Groups = lazy(() => import("../pages/Groups"));
  const Targets = lazy(() => import("../pages/Targets"));
  const SingleTarget = lazy(() => import("../pages/Targets/SingleTarget"));
  const Profile = lazy(() => import("../pages/Profile"));

  return (
    <>
    <Suspense fallback={
        <PageLoader
          h="45"
          w="45"
          style={{
          margin: "15rem auto",
          width: "5%",
          }}
        />
      }>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <PublicRoute>
                  <SignUp/>
              </PublicRoute>
            }
          />
          <Route
            path="/community"
            element={
              <PrivateRoute>
                <Community/>
              </PrivateRoute>
            }
          />
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <Events/>
              </PrivateRoute>
            }
          />
          <Route
            path="/groups"
            element={
              <PrivateRoute>
                <Groups/>
              </PrivateRoute>
            }
          />
          <Route
            path="/targets"
            element={
              <PrivateRoute>
                <Targets/>
              </PrivateRoute>
            }
          />
          <Route
            path="/targets/:id"
            element={
              <PrivateRoute>
                <SingleTarget/>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile/>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
    </>
  );
};

export default AllRoutes;