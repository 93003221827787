import {useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/logo.jpg";
import DummyUser from "../../assets/dummyUser.jpg";
import Icon from "../Icons";
import { signOut } from "../../redux/actions/authAction";
import InputField from "../InputField";
import InputSearch from "../InputSearch";

const TopNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.auth);
  const {profile_image,first_name,last_name} = profileData.userData;
  const activeLink = pathname.split("/")[1];
  const handleActiveLink = (param) => {
    if (param === activeLink) {
      return "active w-full pl-8 font-semibold";
    }
    return "w-full pl-8 font-medium inactive"
  };
  
  const navList = [
    { name: "Community Bants", iconId: "community-icon", tab: "/community", pathName: "community"},
    { name: "Events", iconId: "events-icon", tab: "/events", pathName: "events"},
    { name: "Targets", iconId: "targets-icon", tab: "/targets", pathName: "targets"},
    { name: "Causes", iconId: "causes-icon", tab: "/causes", pathName: "causes" },
    { name: "Learn", iconId: "learn-icon", tab: "/learn", pathName: "learn"},
    { name: "Friends", iconId: "friends-icon", tab: "/friends", pathName: "friends"},
    { name: "Groups", iconId: "friends-icon", tab: "/groups", pathName: "groups"},
  ];

  return (
      <div className=" w-full h-26 left-nav flex pl-14 py-2 bg-white" >
        <img className=" h-7 object-contain mt-5" src={Logo} />
        <section
          className="ml-7 w-73 h-11 mt-4"
        >
          <InputSearch/>
        </section>
        <section className="flex justify-between w-9/12 mt-4">
          <ul className=" w-694 flex">
            {navList.map((navLink, i) => {
              return (
                  <Link to={navLink.tab}>
                      <li key={i} className={handleActiveLink(navLink.pathName) + " text-as"} >
                          <Icon
                              className="mr-2 m-a"
                              id={
                              navLink.pathName === activeLink
                                  ? `${navLink.iconId}2`
                                  : navLink.iconId
                              }
                              width="24px"
                              height="24px"
                          />
                          <p className="text-center mt-2 max-w-18">{navLink.name}</p>
                      </li>
                  </Link>
              );
            })}
          </ul>
          <section className=" w-600 flex">
            <Link to="/profile">
              <span className="flex">
                <img src={profile_image} className="w-10 h-10 br50 object-contain mr-2" />
                <p className="font-bold mt-2 mr-5">{first_name + " " + last_name} </p>
              </span>
            </Link>
            <section className="flex">
              <a className="br50 h-10 w-10 p-2 mr-5 icon-bg">
                <Icon
                    id="sms-icon"
                    width="24px"
                    height="24px"
                />
              </a>
              <a className="br50 h-10 w-10 p-2 mr-5 icon-bg">
                <Icon
                    id="notification-icon"
                    width="24px"
                    height="24px"
                />
              </a>
              <a className="br50 h-10 w-10 p-2 icon-bg">
                <Icon
                    id="arrow-down-icon"
                    width="24px"
                    height="24px"
                />
              </a>
            </section>
            {/* {navList.map((navLink, i) => {
              return (
                  <Link to={navLink.tab}>
                      <li key={i} className={handleActiveLink(navLink.pathName)} >
                          <Icon
                              className="mr-2"
                              id={
                              navLink.pathName === activeLink
                                  ? `${navLink.iconId}2`
                                  : navLink.iconId
                              }
                              width="24px"
                              height="24px"
                          />
                          <span>{navLink.name}</span>
                      </li>
                  </Link>
              );
            })} */}
          </section>

        </section>

      </div>
  );
};

export default TopNav;
{/* <li className="flex pl-6 pt-2 mb-8">
  <img className=" w-10 h-10 object-contain object-center mr-2" src={DummyUser} />
  <aside>
      <h1 className=" text-sm text-black font-semibold">Emeka Anyanwu</h1>
      <p className=" font-medium text-gray-400">Super Admin</p>
  </aside>
</li>
<Link to="/">
    <li className={handleActiveLink("logout")} onClick={() => signOut()(dispatch)} >
        <Icon
            className="mr-2"
            id="logout-icon"
            width="24px"
            height="24px"
        />
        <span>Log out</span>
    </li>
</Link> */}