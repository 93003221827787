// import localStorage from "redux-persist/es/storage";
// import { history } from "../../utils/history";
import { LOGOUT_USER, LOGGED_IN_USER } from "../types/authTypes";
import { IS_LOADING_TRUE, IS_LOADING_FALSE, IS_LOADING_GLOBAL_TRUE, IS_LOADING_GLOBAL_FALSE } from "../types/loadingTypes";
import handleError from "../../utils/handleError";
import { getApi, postApi, putApi } from "../../utils/reqClient";
import { FETCH_GROUPS, FETCH_USER_GROUPS, SEARCH_GROUPS,JOIN_GROUP, FETCH_VYBE_LIST, ADD_GROUP} from "../types/groupTypes";
import { toastSuccess } from "../../components/Toast";
import BASE_URL from "../../api/constants";

export const fetchGroups = () => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_GLOBAL_TRUE });
    const response = await getApi(`groups`);
    if (response.data.status === "success") {
      const { data } = response.data;
      dispatch({ type: FETCH_GROUPS, payload: data });
      dispatch({ type: IS_LOADING_GLOBAL_FALSE });
      return 
    }
    dispatch({ type: IS_LOADING_GLOBAL_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_GLOBAL_FALSE });
    handleError(error);
  }
};
export const fetchUserGroups = (id) => async (dispatch) => {
    try {
      // Set loading to true before endpoint call
      dispatch({ type: IS_LOADING_TRUE });
      const response = await getApi(`mygroups`);
      if (response.data.status === "success") {
        const { data } = response.data;
        dispatch({ type: FETCH_USER_GROUPS, payload: data });
        dispatch({ type: IS_LOADING_FALSE });
        return 
      }
      dispatch({ type: IS_LOADING_FALSE });
    } catch (error) {
      // Set loading to false after endpoint call is not successful
      dispatch({ type: IS_LOADING_FALSE });
      handleError(error);
    }
};
export const searchGroups = (query) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_GLOBAL_TRUE });
    const response = await getApi(`search-groups?search=${query}`);
    if (response.data.status === "success") {
      const { data } = response.data;
      dispatch({ type: SEARCH_GROUPS, payload: data });
      dispatch({ type: IS_LOADING_GLOBAL_FALSE });
      return 
    }
    dispatch({ type: IS_LOADING_GLOBAL_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_GLOBAL_FALSE });
    handleError(error);
  }
};
export const joinGroup = (id,body,setshowModal) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`join-group/${id}`,body);
    if (response.data.status === "success") {
      const { data } = response.data;
      toastSuccess("Group joined successfully")
      dispatch({ type: JOIN_GROUP, payload: data });
      dispatch({ type: IS_LOADING_FALSE });
      setshowModal(false);
      return 
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};
export const fetchVybeList = (id) => async (dispatch) => {
    try {
      // Set loading to true before endpoint call
      dispatch({ type: IS_LOADING_TRUE });
      const response = await getApi(`vybe-list`);
      if (response.data.status === "success") {
        const { data } = response.data;
        dispatch({ type: FETCH_VYBE_LIST, payload: data });
        dispatch({ type: IS_LOADING_FALSE });
        return 
      }
      dispatch({ type: IS_LOADING_FALSE });
    } catch (error) {
      // Set loading to false after endpoint call is not successful
      dispatch({ type: IS_LOADING_FALSE });
      handleError(error);
    }
};
export const createGroup = (body,setOpenModal) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_TRUE });
    const res = await fetch(`${BASE_URL.staging}/group`, {
      method: 'POST',
      headers: {
        //  'Content-Type': 'multipart/form-data'
        // authid: 78,
        'x-api-key': `${process.env.REACT_APP_API_SECRET}`,
        authorizationToken: `${localStorage.getItem("_trybe_web")}`
      },
      body: body
    });
    const data = await res.json();
    if(data.status ==="success"){
      dispatch({ type: ADD_GROUP, payload: data.data });
      dispatch({ type: IS_LOADING_FALSE });
      setOpenModal(false);
    }
    dispatch({ type: IS_LOADING_FALSE });
    return data;
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};
// export const updateTask = (item) => async (dispatch) => {
//   try {
//     // Set loading to true before endpoint call
//     dispatch({ type: IS_LOADING_TRUE });
//     const response = await putApi(`target/task/mark/${item.id}`);
//     if (response.data.status === "success") {
//         dispatch({ type: IS_LOADING_FALSE });
//         if(response.data.message.includes("unmarked")){
//             return dispatch({ type: UNMARK_TASK, payload: item });
//         }
//         return dispatch({ type: MARK_TASK, payload: item });
//     }
//     dispatch({ type: IS_LOADING_FALSE });
//   } catch (error) {
//     // Set loading to false after endpoint call is not successful
//     dispatch({ type: IS_LOADING_FALSE });
//     handleError(error);
//   }
// };
