import axios from "axios";
import BASE_URL from "../api/baseUrl";
import { API_KEY } from "../api/constants";

export const preAuthPostApi = async (url, body, tokens, headers) =>
  axios.post(`${BASE_URL}/${url}`, body, {
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });

export const postApi = async (url, body, headers) =>
  axios.post(`${BASE_URL}/${url}`, body, {
    headers: {
      authorizationToken: `${localStorage.getItem("_trybe_web")}`,
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });

export const putApi = async (url, body, headers) =>
  axios.put(`${BASE_URL}/${url}`, body, {
    headers: {
      authorizationToken: `${localStorage.getItem("_trybe_web")}`,
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });

export const patchApi = async (url, body, headers) =>
  axios.patch(`${BASE_URL}/${url}`, body, {
    headers: {
      authorizationToken: `${localStorage.getItem("_trybe_web")}`,
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });

export const getApi = async (url, params = {}, headers) =>
  axios.get(`${BASE_URL}/${url}`, {
    params: { ...params },
    headers: {
      authorizationToken: `${localStorage.getItem("_trybe_web")}`,
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });

export const deleteApi = async (url, headers) =>
  axios.delete(`${BASE_URL}/${url}`, {
    headers: {
      authorizationToken: `${localStorage.getItem("_trybe_web")}`,
      "x-api-key": `${process.env.REACT_APP_API_SECRET}`,
      ...headers
    }
  });