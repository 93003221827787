import React from "react";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

export const ButtonLoader = ({ color }) => (
  <div data-testid="loader2" className="w-6 m-a">
    <TailSpin color={color || "#fff"} height="21" width="21" />
  </div>
);

ButtonLoader.propTypes = {
  color: PropTypes.string.isRequired
};

const PageLoader = React.memo(props => (
    <div
      style={
        props.style || {
          margin: '15rem auto',
          width: '5%',
        }
      }
    >
      <TailSpin
        color="#E60026"
        height={props.h || 60}
        width={props.w || 60}
      />
    </div>
  ));
  export default PageLoader;